<template>
    <div class="third-page">
      <!-- 背景遮罩层，点击后关闭弹出的框 -->
      <div v-if="expandedBlock" class="overlay" @click="closeExpandedBlock"></div>
  
      <!-- 弹出框 -->
      <div v-if="expandedBlock" class="popup">
        <h2>{{ expandedBlockTitle }}</h2>
        <p>{{ expandedBlockContent }}</p>
      </div>
  
      <div class="content">
        <div class="block top-block" @click="toggleExpand('block1')">
          <h2>解决方案 1</h2>
        </div>
  
        <div class="block right-block" @click="toggleExpand('block2')">
          <h2>解决方案 2</h2>
        </div>
  
        <div class="block bottom-block" @click="toggleExpand('block3')">
          <h2>解决方案 3</h2>
        </div>
  
        <div class="block left-block" @click="toggleExpand('block4')">
          <h2>解决方案 4</h2>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ThirdPage",
    data() {
      return {
        expandedBlock: null, // 当前展开的文本块
      };
    },
    computed: {
      expandedBlockTitle() {
        switch (this.expandedBlock) {
          case 'block1':
            return '解决方案 1';
          case 'block2':
            return '解决方案 2';
          case 'block3':
            return '解决方案 3';
          case 'block4':
            return '解决方案 4';
          default:
            return '';
        }
      },
      expandedBlockContent() {
        switch (this.expandedBlock) {
          case 'block1':
            return '在此添加解决方案 1 的详细内容。适当分点说明。';
          case 'block2':
            return '在此添加解决方案 2 的详细内容。适当分点说明。';
          case 'block3':
            return '在此添加解决方案 3 的详细内容。适当分点说明。';
          case 'block4':
            return '在此添加解决方案 4 的详细内容。适当分点说明。';
          default:
            return '';
        }
      }
    },
    methods: {
      toggleExpand(block) {
        this.expandedBlock = block; // 展开点击的块
      },
      closeExpandedBlock() {
        this.expandedBlock = null; // 关闭弹出框
      }
    },
  };
  </script>
  
  <style scoped>
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  
    html, body {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
    }
  
    .third-page {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-image: url('/src/assets/images/服务.png'); /* 替换为实际的背景路径 */
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
    }
  
    .content {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around; /* 竖向排列，均匀分布 */
      align-items: center;
      padding: 5%;
    }
  
    .block {
      width: 80%;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.4); /* 半透明背景 */
      color: #333;
      padding: 20px;
      border-radius: 15px;
      text-align: center;
      backdrop-filter: blur(10px); /* 磨砂质感 */
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* 阴影效果 */
      transition: all 0.3s ease;
    }
  
    .block:hover {
      background: rgba(255, 255, 255, 0.6); /* 悬停时背景变亮 */
    }
  
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.7); /* 背景变暗 */
      z-index: 10;
    }
  
    .popup {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      padding: 30px;
      background: white;
      border-radius: 15px;
      z-index: 20;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
      text-align: center;
      animation: popup-fade-in 0.3s ease;
    }
  
    @keyframes popup-fade-in {
      from {
        opacity: 0;
        transform: translate(-50%, -60%);
      }
      to {
        opacity: 1;
        transform: translate(-50%, -50%);
      }
    }
  
    h2 {
      font-size: 1.5rem;
      margin-bottom: 10px;
      cursor: pointer;
    }
  
    p {
      font-size: 1rem;
      line-height: 1.5;
    }
  
    /* 自适应小屏幕 */
    @media (max-width: 768px) {
      .block {
        width: 90%;
        padding: 15px;
      }
  
      h2 {
        font-size: 1.2rem;
      }
  
      p {
        font-size: 0.9rem;
      }
  
      .popup {
        width: 90%;
      }
    }
  </style>
  