<template>
    <div class="home-page">
      <div class="content">
        <h1>创新 App 推介</h1>
        <h2>用创新概念吸引用户</h2>
        <button @click="handleClick" class="learn-more-btn">了解更多</button>
      </div>
    </div>
</template>

<script>
export default {
  name: "HomePage", // 保留名称符合 ESLint 规则
  methods: {
    handleClick() {
      alert("了解更多内容即将推出！");
    }
  }
};
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden; /* 防止水平滚动条 */
  }

  .home-page {
    position: fixed; /* 使背景和页面固定 */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('/src/assets/images/首页.png'); /* 替换为实际的背景路径 */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1; /* 确保背景层在内容之下 */
  }

  .content {
    position: absolute; /* 将内容设为绝对定位 */
    bottom: 10%; /* 保证文字在页面的偏下方 */
    left: 5%;    /* 保证文字始终靠左 */
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* 确保文字靠底部对齐 */
    align-items: flex-start; /* 文字靠左对齐 */
    color: #fff;
    padding: 10px;
    border-radius: 15px;
    max-width: 600px;
    width: 90%; /* 保证在小屏幕上也能显示合适 */
  }

  h1 {
    font-size: 3vw; /* 使用 vw 单位让字体随着屏幕大小变化 */
    font-weight: bold;
    margin: 0 0 10px;
  }

  h2 {
    font-size: 1.5vw; /* 使用 vw 单位 */
    font-weight: 300;
    margin-bottom: 20px;
  }

  .learn-more-btn {
    background-color: #42b983;
    color: #fff;
    border: none;
    padding: 12px 24px;
    border-radius: 60px;
    font-size: 1.5vw; /* 按钮的字体大小也自适应 */
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }

  .learn-more-btn:hover {
    background-color: #36a673;
  }

  .learn-more-btn:active {
    transform: scale(0.95);
  }

  /* 针对小屏幕（宽度小于768px）的自适应设置 */
  @media (max-width: 768px) {
    h1 {
      font-size: 5vw; /* 小屏幕下稍大一些的字体 */
    }

    h2 {
      font-size: 3vw; /* 小屏幕下的副标题字体 */
    }

    .learn-more-btn {
      font-size: 3vw; /* 小屏幕下的按钮文字也变大一些 */
    }

    .content {
      bottom: 10%; /* 在小屏幕上稍微提高文字的位置 */
    }
  }

  /* 针对更小的屏幕（宽度小于480px）的自适应设置 */
  @media (max-width: 480px) {
    h1 {
      font-size: 6vw; /* 更小屏幕下的字体 */
    }

    h2 {
      font-size: 4vw; /* 更小屏幕下的副标题 */
    }

    .learn-more-btn {
      font-size: 4vw; /* 更小屏幕下的按钮文字 */
      padding: 10px 20px; /* 减少按钮的 padding */
    }

    .content {
      bottom: 18%; /* 小屏幕上内容的位置 */
      padding: 5px; /* 减少 padding */
    }
  }
</style>
