import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/Home.vue';
import AboutPage from './components/About.vue';
import ServePage from './components/Serve.vue';

// 创建路由
const routes = [
  { path: '/', component: HomePage },
  { path: '/about', component: AboutPage },
  { path: '/serve', component: ServePage },
];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 创建应用并挂载路由
const app = createApp(App);
app.use(router);
app.mount('#app');
