<template>
    <div class="about-page">
      <div class="content">
        <h1>About Us</h1>
        <p>This is the about page where you can learn more about us.</p>
      </div>
    </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  .about-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('/src/assets/images/关于.png'); /* 替换为实际的背景路径 */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .content {
    position: absolute;
    bottom: 10%;
    left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    color: #fff;
    padding: 10px;
    border-radius: 15px;
    max-width: 600px;
    width: 90%;
  }

  h1 {
    color: #42b983;
    font-size: 3vw; /* 字体根据视口变化 */
  }

  p {
    font-size: 1.5vw; /* 字体自适应 */
    color: #fff;
  }

  /* 针对小屏幕（宽度小于768px）的自适应设置 */
  @media (max-width: 768px) {
    h1 {
      font-size: 5vw;
    }

    p {
      font-size: 3vw;
    }

    .content {
      bottom: 10%;
    }
  }

  /* 针对更小的屏幕（宽度小于480px）的自适应设置 */
  @media (max-width: 480px) {
    h1 {
      font-size: 6vw;
    }

    p {
      font-size: 4vw;
    }

    .content {
      bottom: 18%;
      padding: 5px;
    }
  }
</style>
