<template>
  <div id="app">
    <!-- 点击遮罩区域关闭侧边栏 -->
    <div class="overlay" v-if="isSidebarOpen" @click="closeSidebar"></div>

    <!-- 侧边栏按钮 -->
    <div class="menu-button" @click="toggleSidebar">
      {{ isSidebarOpen ? '×' : '☰' }}
    </div>

    <!-- 侧边导航栏（移到右侧） -->
    <div :class="['sidebar', { 'open': isSidebarOpen }]">
      <div class="sidebar-content">
        <router-link @click="closeSidebar" to="/">Home</router-link>
        <router-link @click="closeSidebar" to="/about">About</router-link>
        <router-link @click="closeSidebar" to="/serve">serve</router-link>
      </div>
    </div>

    <!-- 路由内容将显示在这里 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isSidebarOpen: false, // 控制侧边栏的显示和隐藏
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    closeSidebar() {
      this.isSidebarOpen = false; // 关闭侧边栏
    }
  }
};
</script>

<style scoped>
/* 点击页面空白区域关闭侧边栏时的遮罩层 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500; /* 显示在侧边栏之下，按钮之上 */
}

/* 侧边栏从右边滑出 */
.sidebar {
  position: fixed;
  right: -250px; /* 初始状态下隐藏在右侧 */
  top: 0;
  width: 250px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3); /* 半透明背景 */
  backdrop-filter: blur(10px); /* 磨砂效果 */
  box-shadow: -2px 0 12px rgba(0, 0, 0, 0.5); /* 阴影效果 */
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  z-index: 1000; /* 显示在前端 */
}

.sidebar.open {
  right: 0; /* 当侧边栏打开时显示 */
}

.sidebar-content {
  display: flex;
  flex-direction: column;
}

.sidebar-content a {
  margin: 15px 0;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  font-size: 1.2rem;
  transition: background 0.3s ease, transform 0.2s ease;
}

.sidebar-content a:hover {
  background: rgba(0, 0, 0, 0.1);
  transform: translateX(10px); /* 点击或悬停时右移动画效果 */
}

/* 菜单按钮样式 */
.menu-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2.5rem;
  color: white;
  cursor: pointer;
  z-index: 1100; /* 确保按钮在其他元素上面 */
  transition: transform 0.3s ease;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .menu-button {
    font-size: 1.5rem;
  }

  .sidebar-content a {
    font-size: 1rem;
  }
}
</style>
